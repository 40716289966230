<template>
  <chart-component>
    <!-- Blurred box -->
    <!--    <ib-blurred-box-->
    <!--      v-if="subscriptionIsFreeTrial"-->
    <!--      @upgrade="showUpgradeDialog"-->
    <!--    />-->
    <!-- Blurred box -->

    <!-- Title -->
    <template #title>
      {{ $t('charts.profitAndLossProjection') }}
    </template>
    <!-- /Title -->
    <!-- Period switcher -->
    <template #switcher>
      <timeline-period-switcher
        class="switcher"
        @change="switchChartPeriod($event)"
      />
    </template>
    <!-- /Period switcher -->
    <!-- Chart -->
    <template #chart>
      <profit-and-loss-projection-chart
        hide-title
        :period="period"
        :chart-animations="chartAnimations"
        :shared-idea="sharedIdea"
      />
    </template>
    <!-- /Chart -->

    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <yearly-select
          v-model="selectedYear"
          :items="yearsOptions"
        />
        <div>
          <!-- Income -->
          <year-info
            class="mb-3"
            avatar-text="IN"
            :text="$t('totalIncome')"
            circle
            :money="yearTotalIncome"
          />
          <!-- /Income -->
          <!-- Expenses -->
          <year-info
            class="mb-3"
            avatar-text="EX"
            :text="$t('totalExpenses')"
            circle
            :money="yearTotalExpenses"
          />
          <!-- /Expenses -->
          <!-- Net Profit -->
          <year-info
            avatar-text="NP"
            :text="$t('netProfit')"
            circle
            :money="yearNetProfit"
          />
          <!-- /Net Profit -->
        </div>
      </chart-component-right-side>
    </template>
    <!-- /Right side -->
  </chart-component>
</template>

<script>
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import MixinCalculations from '@/mixins/calculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import ProfitAndLossProjectionChart from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Charts/ProfitAndLossProjectionChart'
import TimelinePeriodSwitcher from '@/components/Charts/Timeline/TimelinePeriodSwitcher'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

import { mapMutations, mapState } from 'vuex'
import YearlySelect from '@/components/Charts/Components/YearlySelect.vue'

export default {
  name: 'ProfitAndLossProjection',

  components: {
    YearlySelect,
    ChartComponentRightSide,
    TimelinePeriodSwitcher,
    ProfitAndLossProjectionChart,
    YearInfo,
    ChartComponent
  },

  mixins: [MixinCurrencies, MixinCalculations, MixinUserSubscription],

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly',
      selectedYear: 1
    }
  },

  computed: {
    ...mapState(['idea']),

    yearsOptions () {
      const years = []
      for (let year = 1; year <= this.numberOfYearsToShow; year++) {
        years.push(year)
      }

      return years.map(year => {
        return {
          text: `Y${year}`,
          value: year
        }
      })
    },

    yearTotalIncome () {
      let result = 0

      if (
        this.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome &&
        this.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome[this.selectedYear]
      ) {
        result = this.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome[this.selectedYear]
      }

      return result
    },

    yearTotalExpenses () {
      let result = 0

      if (
        this.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses &&
        this.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses[this.selectedYear]
      ) {
        result = this.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses[this.selectedYear]
      }

      return result
    },

    yearNetProfit () {
      let result = 0

      if (
        this.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit &&
        this.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit[this.selectedYear]
      ) {
        result = this.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit[this.selectedYear]
      }

      return result
    }
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    },

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    }
  }
}
</script>
