<template>
  <div>
    <!-- Button -->
    <ib-button
      class="px-2 px-lg-3"
      variant="white"
      font-family="regular"
      :disabled="disabled"
      @click="onClick"
    >
      {{ $t('pages.businessGuide.profitAndCashFlow.addDialog.fields.dividends.navigation') }}
    </ib-button>
    <!-- /Button -->
    <!-- Dialog Add Dividends -->
    <dialog-add-dividends
      :dialog-add-dividends-visible="dialogVisible"
      @close-dialog-add-dividends="onCloseDialog"
      @close-dialog-on-button="onCloseDialog"
      @on-update-profit-and-cash-flow-view="getProfitAndCashFlow"
    />
    <!-- /Dialog Add Dividends -->
  </div>
</template>

<script>
import DialogAddDividends from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Dialogs/DialogAddDividends'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

export default {
  name: 'AddDividend',

  components: {
    DialogAddDividends
  },

  mixins: [MixinApiCalculations, MixinIdeaRoles],

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      dialogVisible: false
    }
  },

  methods: {
    onClick () {
      if (!this.canEdit) return
      this.dialogVisible = true
    },

    onCloseDialog () {
      this.dialogVisible = false
    }
  }
}
</script>
