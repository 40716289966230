<template>
  <div class="d-flex pt-0 pr-0 pl-0 pb-5 justify-content-center justify-content-md-end">
    <v-select
      v-model="content"
      class="yearly-select"
      background-color="white"
      outlined
      dense
      hide-details
      :items="items"
    />
  </div>
</template>

<script>
export default {
  name: 'YearlySelect',

  props: {
    value: {
      type: [String, Boolean, Number],
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.yearly-select {
  max-width: 74px;
}
</style>
