<template>
  <!-- Dialog Set Income Taxes -->
  <ib-dialog
    id="dialog-set-income-taxes"
    :visible="dialogSetIncomeTaxesVisible"
    fullscreen
    empty-header
    @close="closeDialog"
    @closed="closedDialog"
  >

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper">

      <!-- Header -->
      <header class="header-flex">

        <!-- Title -->
        <h2>{{ $t('pages.businessGuide.profitAndCashFlow.addDialog.fields.incomeTaxes.setIncomeTaxes') }}</h2>
        <!-- /Title -->

      </header>
      <!-- /Header -->

      <!-- Guide -->
      <guide
        :visible="guideVisible"
        :is-dialog="true"
        :labor="true"
        :title="$t('pages.businessGuide.profitAndCashFlow.buttonDialog.incomeTaxes.title')"
        :text="$t('pages.businessGuide.profitAndCashFlow.buttonDialog.incomeTaxes.text')"
        @close="onToggleDialogGuide"
      />
      <!-- /Guide -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="d-block">
          <el-row :gutter="60">
            <el-col :md="12">

              <!-- Estimated Tax Rate -->
              <div v-for="option in options.taxRate" :key="option.id" class="slider-block">

                <!-- Title -->
                <h4>{{ $t('pages.businessGuide.profitAndCashFlow.addDialog.fields.incomeTaxes.estimatedTaxRate') }}</h4>
                <!-- /Title -->

                <!-- Top -->
                <div class="top">
                  <div>
                    <p>{{ form[option.name] + '%' }}</p>
                  </div>
                </div>
                <!-- /Top -->

                <!-- Bottom -->
                <div class="bottom">
                  <el-slider
                    v-model="form[option.name]"
                    :show-tooltip="false"
                    :min="option.min"
                    :max="option.max"
                    :step="typeof option.step !== 'undefined' ? option.step : 1"
                  />
                </div>
                <!-- /Bottom -->

                <!-- Flex -->
                <div class="d-flex justify-content-between">
                  <p class="mt-0 o-50">
                    0%
                  </p>
                  <p class="mt-0 o-50">
                    100%
                  </p>
                </div>
                <!-- /Flex -->

                <!-- How Often Will You Pay -->
                <el-form :model="form">
                  <el-form-item
                    class="input-inside-label"
                    :label="$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.incomeTaxes.howOftenWillYouPay')"
                    :class="{ 'focused': true }"
                  >
                    <ib-select
                      v-model="form.howOftenWillYouPay"
                      :filterable="false"
                      placeholder=" "
                      popper-class="promo-dropdown"
                      :popper-append-to-body="false"
                    >
                      <ib-option
                        v-for="oftenOption in options.howOftenWillYouPay"
                        :key="oftenOption.id"
                        :label="oftenOption.label"
                        :value="oftenOption.value"
                      />
                    </ib-select>
                  </el-form-item>
                </el-form>
                <!-- /How Often Will You Pay -->

              </div>
              <!-- /Estimated Tax Rate -->

            </el-col>
          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->

      <!-- Save & Close -->
      <ib-dialog-footer-fullscreen
        :loading="loading"
        class="pb-1"
        @submit="saveIncomeTaxes"
        @cancel="closeDialog"
      />
      <!-- /Save & Close -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->
  </ib-dialog>
  <!-- /Dialog Set Income Taxes -->
</template>

<script>
import Guide from '@/views/Home/StoryMode/Components/Guide/Guide'
import IbDialogFooterFullscreen from '@/components/_v2/Dialog/IbDialogFooterFullscreen'
import IbOption from '@/components/_v2/Select/IbOption'

export default {
  name: 'DialogSetIncomeTaxes',

  components: {
    Guide,
    IbDialogFooterFullscreen,
    IbOption
  },

  props: {
    dialogSetIncomeTaxesVisible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      guideVisible: true,
      loading: false,
      form: {
        taxRate: this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.taxRate !== 'undefined'
          ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.taxRate
          : 0,
        howOftenWillYouPay: this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.howOftenWillYouPay !== 'undefined'
          ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.howOftenWillYouPay
          : 'monthly'
      },
      options: {
        taxRate: [
          {
            id: 1,
            name: 'taxRate',
            min: 0,
            max: 100,
            step: 1
          }
        ],
        howOftenWillYouPay: [
          {
            id: 1,
            label: this.$t('monthly'),
            value: 'monthly'
          }, {
            id: 2,
            label: this.$t('quarterly'),
            value: 'quarterly'
          }, {
            id: 3,
            label: this.$t('annually'),
            value: 'annually'
          }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close-dialog-set-income-taxes')
    },

    closedDialog () {
      this.form.taxRate = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.taxRate !== 'undefined'
        ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.taxRate
        : 0
      this.form.howOftenWillYouPay = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.howOftenWillYouPay !== 'undefined'
        ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.incomeTax.howOftenWillYouPay
        : 'monthly'
    },

    saveIncomeTaxes () {
      this.loading = true
      this.$http.post(`story-mode/forecast/profit-and-cash-flow/income-tax/${this.$store.state.idea.id}`, this.form)
        .then((response) => {
          if (response.status === 201) {
            this.$store.commit('idea/setIncomeTax', response.data.payload.incomeTax)
            this.closeDialog()
          }
          this.$emit('on-update-profit-and-cash-flow-view')
        }).finally(() => {
          this.loading = false
        })
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    }
  }
}
</script>
