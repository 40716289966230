<template>

  <!-- Profit And Cash Flow -->
  <div id="story-mode-profit">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.profitAndCashFlow" />

      <!-- Content -->
      <div
        v-if="loaded.profitAndCashFlow"
        class="content"
        :class="{ 'position-relative': isProfitAndCashFlowCompleted }"
      >
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': isProfitAndCashFlowCompleted }"
              :title="$t('steps.projections.items.profitAndCashFlow')"
              :guide-visible="guideVisible"
              :completed-step-name="'profitAndCashFlowCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->

          </header>
        <!-- /Page Header -->
        </v-app>
        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Filled & Buttons -->
        <div class="table-view" :class="{ 'filled': showFilledClass }">

          <!-- Tabs -->
          <el-tabs v-model="activeName">

            <!-- Income / Expenses -->
            <el-tab-pane :label="$t('pages.businessGuide.profitAndCashFlow.profitAndLoss')" name="first">
              <div v-if="activeName === 'first'" class="switcher-inner-wrapper">
                <profit-and-loss-projection
                  v-if="showIncomeAndExpenses"
                  chart-animations
                  class="pt-4"
                />
                <template v-if="!showIncomeAndExpenses">
                  <!--                  <img-->
                  <!--                    src="@/assets/img/tutorial/profit-cash-flow-handwriting.png"-->
                  <!--                    class="add-revenue-and-cost-img m-auto position-absolute w-25 pt-4"-->
                  <!--                  >-->
                  <div class="placeholder timeline" />
                </template>
              </div>
            </el-tab-pane>
            <!-- /Income / Expenses -->

            <!-- Cash Flow -->
            <el-tab-pane :label="$t('pages.businessGuide.profitAndCashFlow.cashFlow')" name="second">
              <div v-if="activeName === 'second'" class="switcher-inner-wrapper">
                <cash-flow-projection
                  v-if="showCashFlow"
                  class="pt-4"
                  chart-animations
                />
                <div v-if="!showCashFlow" class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Cash Flow -->

            <!-- Summary -->
            <el-tab-pane :label="$t('pages.businessGuide.profitAndCashFlow.summary')" name="third">
              <div v-if="activeName === 'third'" class="switcher-inner-wrapper">
                <profit-and-loss-table v-if="showIncomeAndExpenses" class="pt-4 mb-4" />
                <cash-flow-table v-if="showCashFlow" />
                <div v-if="!showIncomeAndExpenses && !showCashFlow" class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Summary -->

          </el-tabs>
          <!-- /Tabs -->

          <!-- Dividends, Cash Cycle & Income Taxes Buttons -->
          <div class="button-group mb-3 justify-content-end">
            <!-- Dividends -->
            <div class="d-flex mr-2">
              <div v-if="!canEdit || isProfitAndCashFlowCompleted" class="completed-overlay" @click="checkProfitAndCashFlow" />
              <add-dividend class="d-flex" />
            </div>
            <!-- /Dividends -->

            <!-- Cash Cycle -->
            <div class="d-flex mr-2">
              <div v-if="!canEdit || isProfitAndCashFlowCompleted" class="completed-overlay" @click="checkProfitAndCashFlow" />
              <set-cash-cycle class="d-flex" />
            </div>
            <!-- /Cash Cycle -->

            <!-- Income Taxes -->
            <div class="d-flex">
              <div v-if="!canEdit || isProfitAndCashFlowCompleted" class="completed-overlay" @click="checkProfitAndCashFlow" />
              <set-income-taxes class="d-flex" />
            </div>
            <!-- /Income Taxes -->

          </div>
          <!-- /Dividends, Cash Cycle & Income Taxes Buttons -->

        </div>
        <!-- /Charts And Table Filled & Buttons -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'forecast'"
        :step="'profit-and-cash-flow'"
        :concept-name="'profitAndCashFlowMoreDetails'"
        :completed-step-name="'profitAndCashFlowCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- Container -->

    <!-- Main Bottom Bar -->
    <!-- TODO: step-filled should be populated -->
    <main-bottom-bar
      :back="backNavigation"
      :completed-step-name="'profitAndCashFlowCompleted'"
      :step-filled="$store.state.idea.storyMode.forecast.profitAndCashFlow.dividends.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="profitAndCashFlowExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="profit-and-cash-flow"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

  </div>
  <!-- /Profit And Cash Flow -->

</template>

<script>
import AddDividend from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/AddDividend'
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import CashFlowProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/CashFlowProjection'
import CashFlowTable from './Tables/CashFlowTable'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import ProfitAndLossProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/ProfitAndLossProjection'
import ProfitAndLossTable from './Tables/ProfitAndLossTable'
import SetCashCycle from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/SetCashCycle'
import SetIncomeTaxes from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/SetIncomeTaxes'

import { mapActions, mapState } from 'vuex'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'ProfitAndCashFlow',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    AddMoreDetails,
    CashFlowTable,
    DashboardHeader,
    DialogProcessCompleted,
    ProfitAndLossTable,
    MainBottomBar,
    MainNavigation,
    Loader,
    ProfitAndLossProjection,
    CashFlowProjection,
    AddDividend,
    SetCashCycle,
    SetIncomeTaxes
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinApiCalculations,
    MixinIdeaRoles
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      activeName: 'first',
      dialogProcessCompletedVisible: false,
      guideVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    showIncomeAndExpenses () {
      return this.idea.storyMode.forecast.revenueStreams.revenueStreams.length !== 0 ||
        this.idea.storyMode.forecast.directCosts.directCosts.length !== 0 ||
        this.idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length !== 0 ||
        this.idea.storyMode.forecast.marketingBudget.marketingElements.length !== 0 ||
        this.idea.storyMode.forecast.otherOverheads.otherOverheads.length !== 0 ||
        this.idea.storyMode.setUp.startupCosts.startupCosts.length !== 0 ||
        this.idea.storyMode.setUp.financing.finances.length !== 0
    },

    showCashFlow () {
      return this.idea.storyMode.forecast.revenueStreams.revenueStreams.length !== 0 ||
        this.idea.storyMode.forecast.directCosts.directCosts.length !== 0 ||
        this.idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length !== 0 ||
        this.idea.storyMode.forecast.marketingBudget.marketingElements.length !== 0 ||
        this.idea.storyMode.forecast.otherOverheads.otherOverheads.length !== 0 ||
        this.idea.storyMode.setUp.startupCosts.startupCosts.length !== 0 ||
        this.idea.storyMode.setUp.financing.finances.length !== 0 ||
        this.idea.storyMode.forecast.profitAndCashFlow.dividends.length !== 0
    },

    showFilledClass () {
      const incomeExpense = this.activeName === 'first' && this.showIncomeAndExpenses
      const cashFlow = this.activeName === 'second' && this.showCashFlow
      const summary = this.activeName === 'third' && (this.showIncomeAndExpenses || this.showCashFlow)

      return incomeExpense || cashFlow || summary
    },

    isProfitAndCashFlowCompleted () {
      return this.idea.storyMode.common.completedSteps.profitAndCashFlowCompleted
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.otherOverheads'),
        route: 'business-guide-projections-other-overheads'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.ideaScore'),
        route: 'validation-idea-score'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getProfitAndCashFlow()
    }
  },

  async created () {
    await this.getProfitAndCashFlow()
    await this.fetchProfitAndCashFlow()
    this.openGuide('profitCashFlowGuide')
  },

  methods: {
    ...mapActions('idea', ['fetchProfitAndCashFlow']),

    checkProfitAndCashFlow () {
      if (!this.canEdit) return
      if (this.isProfitAndCashFlowCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    }
  }
}
</script>

<style scoped>
#story-mode-profit {
  margin-left: 0;
}
</style>
