<template>
  <!-- Chart -->
  <vue-apex-charts
    ref="chart"
    type="line"
    :height="chartAdjustment.chartHeight"
    :options="chartOptions"
    :series="series"
  />
  <!-- /Chart -->
</template>

<script>
import EventBus from '@/event-bus'
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import VueApexCharts from 'vue-apexcharts'
import responsiveChartOptions from '@/data/responsiveChartOptions'

export default {
  name: 'CashFlowProjectionChart',

  components: { VueApexCharts },

  mixins: [MixinCalculations, MixinCharts, MixinCurrencies],

  props: {
    period: {
      type: String,
      default: 'monthly'
    },
    chartAdjustment: {
      type: Object,
      default: () => ({
        chartHeight: 370,
        headingTitleFontSize: '22px',
        xLabelTitleFontSize: '12px',
        yLabelTitleFontSize: '14px',
        yaxisLabelsMinWidth: {
          breakDown: 70
        }
      })
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          background: '#fff',
          fontFamily: 'outfit-light,arial',
          animations: {
            enabled: this.chartAnimations,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            }
          }
        },
        markers: {
          size: 5,
          hover: {
            size: 8
          },
          strokeColors: '#4cda63',
          colors: ['#fff'],
          discrete: []
        },
        stroke: {
          show: false,
          curve: 'straight',
          colors: ['rgba(0,0,0,0.0)']
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabledOnSeries: [0],
          marker: {
            show: false
          },
          fillSeriesColor: false,
          y: {
            formatter: this.formatTooltip
          },
          x: {
            show: false
          },
          theme: 'dark'
        },
        legend: {
          show: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [-100, 0, 100],
            colorStops: [
              {
                offset: 0,
                color: '#4cda63',
                opacity: 0.25
              },
              {
                offset: 50,
                color: '#4cda63',
                opacity: 0.25
              },
              {
                offset: 50,
                color: '#e53935',
                opacity: 0.25
              },
              {
                offset: 100,
                color: '#e53935',
                opacity: 0.25
              }
            ]
          },
          colors: ['#4cda63', '#e53935']
        },
        title: {
          text: !this.hideTitle ? 'Cash Flow Projection' : '',
          margin: 20,
          offsetX: 20,
          offsetY: 30,
          style: {
            fontSize: this.chartAdjustment.headingTitleFontSize,
            color: '#263238',
            fontFamily: 'outfit-light,arial'
          }
        },
        xaxis: {
          categories: this.getCategories(),
          tickAmount: 12,
          type: 'category',
          labels: {
            show: true,
            style: {
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: this.formatChartYLabels,
            show: true,
            align: 'right',
            style: {
              color: '#afafb1',
              fontSize: this.chartAdjustment.xLabelTitleFontSize,
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-yaxis-label',
              opacity: 0.7
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          },
          crosshairs: {
            show: false,
            width: 0,
            position: 'back',
            opacity: 0,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0
            }
          }
        },
        grid: {
          borderColor: 'transparent',
          padding: {
            left: 20
          }
        },
        responsive: responsiveChartOptions
      }
    }
  },

  computed: {
    series () {
      const series = [
        {
          name: 'Net Cash',
          type: 'area',
          data: []
        },
        // Because apexcharts bug we need to add two more series
        {
          name: '',
          type: 'area',
          data: []
        },
        {
          name: '',
          type: 'column',
          data: []
        }
      ]

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.cashFlowData
      ) {
        series[0].data = this.createSeriesDataFromCalculation(
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.cashFlowData
        )

        this.$set(this.chartOptions.markers, 'discrete', [])
        for (let i = 0; i < series[0].data.length; i++) {
          if (series[0].data[i] <= 0) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.chartOptions.markers.discrete.push({
              seriesIndex: 0,
              dataPointIndex: i,
              fillColor: '#fff',
              strokeColor: series[0].data[i] === 0 ? '#cac5c5' : '#e53935',
              size: 5
            })
          }
        }
      }

      return series
    }
  },

  watch: {
    period: {
      handler () {
        this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
        this.updateColorStops(this.series)
        if (this.$refs.chart) {
          this.$refs.chart.refresh()
        }
      },
      immediate: true
    }
  },

  mounted () {
    EventBus.$on('idea-settings-update', () => {
      if (this.$refs.chart !== undefined) {
        this.$refs.chart.refresh()
      }
    })
    this.updateColorStops(this.series)
  },

  methods: {
    updateColorStops (series) {
      const min = Math.min(...series[0].data)
      const max = Math.max(...series[0].data)
      const sum = Math.abs(min) + Math.abs(max)
      let offset = 100 * max / sum

      if (max > 0 && min > 0) {
        offset = 100
      }

      if (max < 0 && min < 0) {
        offset = 0
      }

      this.chartOptions.fill.gradient.colorStops[1].offset = offset
      this.chartOptions.fill.gradient.colorStops[2].offset = offset
    }
  }
}
</script>

<style scoped>

</style>
