<template>
  <!-- Dialog Set Cash Cycle -->
  <ib-dialog
    id="dialog-set-cash-cycle"
    :visible="dialogSetCashCycleVisible"
    fullscreen
    empty-header
    @close="closeDialog"
    @closed="closedDialog"
  >

    <!-- Content Wrapper -->
    <ib-dialog-container max-width="1200px" class="content-wrapper">

      <!-- Header -->
      <header class="header-flex">

        <!-- Title -->
        <h2>{{ $t('pages.businessGuide.profitAndCashFlow.addDialog.fields.cashCycle.setCashCycle') }}</h2>
        <!-- /Title -->

      </header>
      <!-- /Header -->

      <!-- Guide -->
      <guide
        :visible="guideVisible"
        :is-dialog="true"
        :labor="true"
        :title="$t('pages.businessGuide.profitAndCashFlow.buttonDialog.cashCycle.title')"
        :text="$t('pages.businessGuide.profitAndCashFlow.buttonDialog.cashCycle.text')"
        @close="onToggleDialogGuide"
      />
      <!-- /Guide -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">
          <el-row :gutter="60">
            <el-col>

              <!-- Sliders Wrapper -->
              <div class="sliders-wrapper">
                <el-row :gutter="20">

                  <!-- Accounts Receivable -->
                  <el-col :md="12" style="padding: 0;">
                    <div class="accounts px-3">

                      <!-- Title -->
                      <h5 class="mt-0">
                        {{ $t('pages.businessGuide.profitAndCashFlow.addDialog.fields.cashCycle.accountsReceivable') }}
                      </h5>
                      <!-- /Title -->

                      <!-- Sliders -->
                      <el-row :gutter="20">
                        <el-col
                          v-for="option in options.accountsReceivable"
                          :key="option.id"
                          class="slider-block"
                          :md="12"
                        >

                          <!-- Top -->
                          <div class="top">
                            <div class="block">
                              <p class="desc">
                                {{ option.title }}
                              </p>
                              <p>{{ getNiceValue(option.name, form[option.name]) }}</p>
                            </div>
                          </div>
                          <!-- /Top -->

                          <!-- Bottom -->
                          <div class="bottom">
                            <el-slider
                              v-model="form[option.name]"
                              :show-tooltip="false"
                              :min="option.min"
                              :max="option.max"
                              :step="typeof option.step !== 'undefined' ? option.step : 1"
                            />
                          </div>
                          <!-- /Bottom -->

                          <!-- Flex -->
                          <div class="d-flex justify-content-between">
                            <p class="mt-0 o-50">
                              {{ getLimitLabel(option.name, option.min) }}
                            </p>
                            <p class="mt-0 o-50">
                              {{ getLimitLabel(option.name, option.max) }}
                            </p>
                          </div>
                          <!-- /Flex -->

                        </el-col>
                      </el-row>
                      <!-- /Sliders -->

                    </div>
                  </el-col>
                  <!-- /Accounts Receivable -->

                  <!-- Accounts Payable -->
                  <el-col :md="12" style="padding: 0;">
                    <div class="accounts second px-3">

                      <!-- Title -->
                      <h5 class="mt-0">
                        {{ $t('pages.businessGuide.profitAndCashFlow.addDialog.fields.cashCycle.accountsPayable') }}
                      </h5>
                      <!-- /Title -->

                      <!-- Sliders -->
                      <el-row :gutter="20">
                        <el-col
                          v-for="option in options.accountsPayable"
                          :key="option.id"
                          class="slider-block"
                          :md="12"
                        >

                          <!-- Top -->
                          <div class="top">
                            <div class="block">
                              <p class="desc">
                                {{ option.title }}
                              </p>
                              <p>{{ getNiceValue(option.name, form[option.name]) }}</p>
                            </div>
                          </div>
                          <!-- /Top -->

                          <!-- Bottom -->
                          <div class="bottom">
                            <el-slider
                              v-model="form[option.name]"
                              :show-tooltip="false"
                              :min="option.min"
                              :max="option.max"
                              :step="typeof option.step !== 'undefined' ? option.step : 1"
                            />
                          </div>
                          <!-- /Bottom -->

                          <!-- Flex -->
                          <div class="d-flex justify-content-between">
                            <p class="mt-0 o-50">
                              {{ getLimitLabel(option.name, option.min) }}
                            </p>
                            <p class="mt-0 o-50">
                              {{ getLimitLabel(option.name, option.max) }}
                            </p>
                          </div>
                          <!-- /Flex -->

                        </el-col>
                      </el-row>
                      <!-- /Sliders -->

                    </div>
                  </el-col>
                  <!-- /Accounts Payable -->

                </el-row>
              </div>
              <!-- /Sliders Wrapper -->

            </el-col>
          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->

      <!-- Save & Close -->
      <ib-dialog-footer-fullscreen
        :loading="loading"
        class="pb-1"
        @submit="saveCashCycle"
        @cancel="closeDialog"
      />
      <!-- /Save & Close -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->
  </ib-dialog>
  <!-- /Dialog Set Cash Cycle -->
</template>

<script>
import Guide from '@/views/Home/StoryMode/Components/Guide/Guide'
import IbDialogFooterFullscreen from '@/components/_v2/Dialog/IbDialogFooterFullscreen'

export default {
  name: 'DialogSetCashCycle',

  components: {
    Guide,
    IbDialogFooterFullscreen
  },

  props: {
    dialogSetCashCycleVisible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      guideVisible: true,
      loading: false,
      form: {
        salesOnCredit: this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.salesOnCredit !== 'undefined'
          ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.salesOnCredit
          : 0,
        daysToCollect: this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToCollect !== 'undefined'
          ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToCollect
          : 0,
        purchasesOnCredit: this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.purchasesOnCredit !== 'undefined'
          ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.purchasesOnCredit
          : 0,
        daysToPay: this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToPay !== 'undefined'
          ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToPay
          : 0
      },
      options: {
        accountsReceivable: [
          {
            id: 1,
            title: this.$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.cashCycle.salesOnCredit'),
            name: 'salesOnCredit',
            min: 0,
            max: 100,
            step: 1
          },
          {
            id: 2,
            title: this.$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.cashCycle.daysToCollect'),
            name: 'daysToCollect',
            min: 0,
            max: 180,
            step: 30
          }
        ],
        accountsPayable: [
          {
            id: 1,
            title: this.$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.cashCycle.purchasesOnCredit'),
            name: 'purchasesOnCredit',
            min: 0,
            max: 100,
            step: 1
          },
          {
            id: 2,
            title: this.$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.cashCycle.daysToPay'),
            name: 'daysToPay',
            min: 0,
            max: 180,
            step: 30
          }
        ]
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close-dialog-set-cash-cycle')
    },

    closedDialog () {
      this.form.salesOnCredit = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.salesOnCredit !== 'undefined'
        ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.salesOnCredit
        : 0
      this.form.daysToCollect = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToCollect !== 'undefined'
        ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToCollect
        : 0
      this.form.purchasesOnCredit = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.purchasesOnCredit !== 'undefined'
        ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.purchasesOnCredit
        : 0
      this.form.daysToPay = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle !== null && typeof this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToPay !== 'undefined'
        ? this.$store.state.idea.storyMode.forecast.profitAndCashFlow.cashCycle.daysToPay
        : 0
    },

    saveCashCycle () {
      this.loading = true
      this.$http.post(`story-mode/forecast/profit-and-cash-flow/cash-cycle/${this.$store.state.idea.id}`, this.form)
        .then((response) => {
          if (response.status === 201) {
            this.$store.commit('idea/setCashCycle', response.data.payload.cashCycle)
            this.closeDialog()
          }
          this.$emit('on-update-profit-and-cash-flow-view')
        }).finally(() => {
          this.loading = false
        })
    },

    getNiceValue (name, value) {
      if (name === 'salesOnCredit' || name === 'purchasesOnCredit') {
        return value + '%'
      }

      return value + ` ${this.$t('days')}`
    },

    getLimitLabel (name, value) {
      if (name === 'salesOnCredit' || name === 'purchasesOnCredit') {
        return value + '%'
      }

      return value
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    }
  }
}
</script>
